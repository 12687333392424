const FADE_IN = 1010;
const NEXT_SLIDE = 5000;
const CURRENT = "current";
const FADE_CLASS = "fade-in";
const SLIDE_ATTR = "data-slide";

const deferCallback =
  <T extends unknown[]>(callback: (...args: T) => void, delay: number) =>
  (...args: T) =>
    setTimeout(callback, delay, ...args);

const loadSlideImage = (image: HTMLImageElement): void => {
  const src = image.dataset.slide;
  if (src) {
    image.src = src;
    image.removeAttribute(SLIDE_ATTR);
  }
};

const cleanup = (prev: HTMLImageElement, current: HTMLImageElement): void => {
  prev.classList.remove(CURRENT);
  current.classList.remove(FADE_CLASS);
  current.classList.add(CURRENT);
};

const incrementIndexWrapped = (max: number, index: number): number =>
  (index + 1) % max;

const fadeSlide = (
  slides: NodeListOf<HTMLImageElement>,
  index: number,
  max: number
): void => {
  const next = incrementIndexWrapped(max, index);
  const nextSlide = slides[next];
  nextSlide.classList.add(FADE_CLASS);
  loadSlideImage(slides[incrementIndexWrapped(max, next)]);
  deferredCleanup(slides[index], nextSlide);
  deferredFadeSlide(slides, next, max);
};

const deferredCleanup = deferCallback(cleanup, FADE_IN);
const deferredFadeSlide = deferCallback(fadeSlide, NEXT_SLIDE);

export const initCarousel = (selector: string): void => {
  const slides = document.querySelectorAll<HTMLImageElement>(selector);

  if (slides.length > 1) {
    loadSlideImage(slides[1]);
    deferredFadeSlide(slides, 0, slides.length);
  }
};
