import { EVENT_ONCE } from "./constants";
import { initIntersectionHandler } from "./intersectionHandler";

/**
 * Initialise images to be lazy loaded. Expects images
 * to have a class prefixed with `lazy-` and for the `src`
 * or `srcset` to be provided as `data-src` and `data-srcset`.
 * @param selector Selector for finding images, example: `.content img.lazy-image`
 */
export const lazyLoadImages = (selector: string): void => {
  const imageClass = /(?:img)?\.(lazy-\w+)/g.exec(selector)?.[1];

  if (!imageClass) {
    return;
  }

  const images = document.querySelectorAll<HTMLImageElement>(selector);

  if (images.length) {
    initIntersectionHandler(images, (entry, observer) => {
      if (entry.isIntersecting) {
        const image = entry.target;

        if (image.dataset.src) {
          image.src = image.dataset.src;
          image.addEventListener(
            "load",
            () => image.classList.remove(imageClass),
            EVENT_ONCE
          );
        }

        if (image.dataset.srcset) {
          image.srcset = image.dataset.srcset;
        }

        observer.unobserve(image);
      }
    });
  }
};
