import "../sass/main.scss";
import { initCarousel } from "./carousel";
import { EVENT_ONCE } from "./constants";
import { initEmailLinks } from "./email";
import { lazyLoadImages } from "./lazyloader";
import { initMenu } from "./menu";

const init: [string, (selector: string) => void][] = [
  [".mobile-menu", initMenu],
  [".lazy-image", lazyLoadImages],
  [".carousel .slide", initCarousel],
  ["a[data-rota]", initEmailLinks],
];

document.addEventListener(
  "DOMContentLoaded",
  () => {
    for (const [selector, initialiser] of init) {
      initialiser(selector);
    }
  },
  EVENT_ONCE
);
