export type IntersectionCallback<T extends Element> = (
  entry: IntersectionObservable<T>,
  observer: IntersectionObserver
) => void;

export interface IntersectionObservable<T extends Element>
  extends IntersectionObserverEntry {
  target: T;
}

export const initIntersectionHandler = <T extends Element>(
  targetElements: Iterable<T>,
  intersectionCallback: IntersectionCallback<T>,
  intersectionOptions?: IntersectionObserverInit
): void => {
  const observer = new IntersectionObserver((entries, observer) => {
    for (const entry of entries) {
      intersectionCallback(entry as IntersectionObservable<T>, observer);
    }
  }, intersectionOptions);

  for (const element of targetElements) {
    observer.observe(element);
  }
};
