const unrot = (str: string): string => {
  let result = "";
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    result += String.fromCharCode(
      char >= 33 && char <= 126 ? 33 + ((char + 14) % 94) : char
    );
  }
  return result;
};

const relink = (link: HTMLAnchorElement): void => {
  const out = link.dataset.rota;
  if (out) {
    const payload = unrot(atob(out));
    link.href = `mailto:${(link.innerText = payload)}`;
    link.target = "_blank";
    link.removeAttribute("data-rota");
  }
};

export const initEmailLinks = (selector: string): void =>
  document.querySelectorAll<HTMLAnchorElement>(selector).forEach(relink);
